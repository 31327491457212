import React from "react"
import { useTheme } from "../../context/ThemeContext"
import lazyLoad from "../../utilities/handleLazyLoad"

// Components
import { Link } from "gatsby"
const DiscountCode = lazyLoad(() => import("./Items/DiscountCode"))
const DiscountBtn = lazyLoad(() => import("./Items/DiscountBtn"))
const DiscountTxt = lazyLoad(() => import("./Items/DiscountTxt"))

/**
 * @param modifierClass - class for specific style
 * @param isCart - is the box located in the cart
 * */
const DiscountCodeBox = ({ modifierClass, isCart }) => {
  const { shipping, discount, handleSidebar } = useTheme()

  const discountType = "bg" // pink || bg

  return (
    <>
      {shipping && !discount && (
        <>
          {isCart ? (
            <Link
              to={"/category/supplements"}
              role="button"
              className={`discount--${discountType} discount-box ${modifierClass}`}
            >
              <div className="row align-items-end">
                <div className="col-12 col-sm-8 col-md-6">
                  <DiscountCode />
                  <DiscountTxt />
                  <DiscountBtn />
                </div>
                <div className="col-12 col-sm-4 col-md-6">
                  <img
                    src="/images/discounts/discount-products-md.png"
                    alt="Supplements."
                    width={283}
                    height={147}
                    className="discount-box__img d-none d-md-block"
                  />
                  <img
                    src="/images/discounts/discount-products-sm.png"
                    alt="Supplements."
                    width={187}
                    height={167}
                    className="discount-box__img d-block d-md-none"
                  />
                </div>
              </div>
            </Link>
          ) : (
            <Link
              to={"/category/supplements"}
              role="button"
              onClick={handleSidebar}
              className={`discount--${discountType} discount--bg-sm discount-box ${modifierClass}`}
            >
              {/*Discount Code*/}
              <DiscountCode />

              <div className="row">
                <div className="col-11 col-sm-10">
                  <DiscountTxt />
                  <DiscountBtn />
                </div>
              </div>

              <img
                src="/images/discounts/discount-products-sm.png"
                alt="Supplements."
                width={187}
                height={167}
                className="discount-box__img"
              />
            </Link>
          )}
        </>
      )}
    </>
  )
}
export default DiscountCodeBox
