import React, { useState } from "react"
import { useTheme } from "../../context/ThemeContext"

// Utilities
import { formatProductDescriptionCartMessage, formatProductTitle } from "../../utilities/formatProductInfo"
import { handleProductImage } from "../../utilities/handleProductImage"
import lazyLoad from "../../utilities/handleLazyLoad"

// Data
import FULL_PRODUCT_DATA from "../../../static/data/full-product"

// Components
import { Link } from "gatsby"
const Image = lazyLoad(() => import("../Image/Image"))
const Quantity = lazyLoad(() => import("../Quantity/Quantity"))
const ProductIcon = lazyLoad(() => import("../ProductIcon/ProductIcon"))

/**
 * @param shopifyProduct - the product object from Shopify
 * @param cartItem - the cart item object from localStorage
 * @param handleReviewProduct - handle review product popup
 **/

export default function CartItem({ shopifyProduct, cartItem, handleReviewProduct }) {
  const { updateQuantity, removeLineItemInCart, giftId } = useTheme()

  /*====================================
  HANDLE PRODUCT DATA
  =====================================*/
  // It there is a heart, replace it with an icon
  const title = formatProductTitle(shopifyProduct.title, shopifyProduct)

  /*====================================
  HANDLE QUANTITY
  =====================================*/
  const [quantity, setQuantity] = useState(cartItem.quantity)

  const handleQuantity = quantity => {
    setQuantity(quantity)
    updateQuantity(cartItem, quantity)
  }

  /*====================================
    HANDLE IMAGE
  =====================================*/
  const imageSrc = handleProductImage(cartItem, shopifyProduct)

  /*====================================
    HANDLE PRODUCT DESCRIPTION MESSAGE
  =====================================*/
  // Set product description message according to the selling plan or selected option
  const productDescriptionMessage = formatProductDescriptionCartMessage(cartItem, shopifyProduct)

  return (
    <div className="cart-item">
      <Link to={`/products/${shopifyProduct.handle}`} className="cart-item__img">
        <ProductIcon product={cartItem} />

        {imageSrc.length ? (
          <img src={imageSrc} width="145" height="120" alt="" className="lazy no-shadow" />
        ) : (
          <Image filename="default-img.png" />
        )}
      </Link>
      <div className="cart-item__content d-flex">
        <div className="d-flex flex-wrap flex-grow-1 pl--20">
          <div className="full-width">
            <div className="d-flex justify-content-between align-items-center full-width">
              <h6 className="txt--18 fw--medium mb--5 flex-grow-1 txt--capitalize">
                <Link to={`/products/${shopifyProduct.handle}`} dangerouslySetInnerHTML={{ __html: title }} />
              </h6>

              {/*remove*/}
              <div className="cart-item__column--xs d-block d-md-none cart-item__columns--mobile">
                <button
                  className="cart-item__btn cart-item__remove"
                  aria-label="Remove."
                  onClick={() => removeLineItemInCart(cartItem)}
                >
                  <i className="fa-solid fa-trash-can" />
                </button>
              </div>
            </div>

            {/*Apparel Description*/}
            {!FULL_PRODUCT_DATA.hasOwnProperty(cartItem.productId) && (
              <div className="cart-item__options cart-item--info">
                <span className="d-inline-block">
                  <strong>Size:</strong> {cartItem.options.size}
                </span>
                <span className="d-inline-block mr--5 ml--5">|</span>
                <span className="d-inline-block">
                  <strong>Color:</strong> {cartItem.options.color}
                </span>
              </div>
            )}
            {/*Product Description*/}
            {productDescriptionMessage.length > 0 && (
              <span className="cart-item--info" dangerouslySetInnerHTML={{ __html: productDescriptionMessage }} />
            )}
          </div>

          <div className="cart-item__buttons d-none d-md-block">
            <button
              className="cart-item__btn cart-item__edit"
              aria-label="Review."
              onClick={() => handleReviewProduct(cartItem)}
            >
              Review Item
            </button>
          </div>

          <div className="cart-item__columns--mobile d-none d-sm-block d-md-none">
            <div className="d-flex align-items-center mb--10">
              {/*price*/}
              <div className="cart-item__column">
                <p className="txt--16">
                  Price:{" "}
                  <strong>
                    {cartItem.productId === giftId || cartItem.price === 0
                      ? "FREE"
                      : `$${(Math.round(cartItem.price * 100) / 100).toFixed(2)}`}
                  </strong>
                </p>
              </div>
              {/*quantity*/}
              <div className="cart-item__column">
                <Quantity
                  cartItem={cartItem}
                  giftId={giftId}
                  quantity={quantity}
                  handleQuantity={handleQuantity}
                  modifierClass="cart-item__subscribed"
                  noLabel={true}
                />
              </div>
              {/*total price*/}
              <div className="cart-item__column">
                <p className="txt--16 txt--pink">
                  Total Price:{" "}
                  <strong>
                    {cartItem.productId === giftId || cartItem.price === 0
                      ? "FREE"
                      : `$${(Math.round(cartItem.price * quantity * 100) / 100).toFixed(2)}`}
                  </strong>
                </p>
              </div>
            </div>

            <div className="cart-item__buttons">
              <button
                className="cart-item__btn cart-item__edit"
                aria-label="Review."
                onClick={() => handleReviewProduct(cartItem)}
              >
                Review Item
              </button>
            </div>
          </div>
        </div>

        <div className="cart-item__columns cart-item__columns--desktop">
          {/*price*/}
          <div className="cart-item__column cart-item__column--sm">
            <p className="txt--16">
              <strong>
                {cartItem.productId === giftId || cartItem.price === 0
                  ? "FREE"
                  : `$${(Math.round(cartItem.price * 100) / 100).toFixed(2)}`}
              </strong>
            </p>
          </div>
          {/*quantity*/}
          <div className="cart-item__column">
            <Quantity
              cartItem={cartItem}
              giftId={giftId}
              quantity={quantity}
              handleQuantity={handleQuantity}
              modifierClass="cart-item__subscribed"
              noLabel={true}
            />
          </div>
          {/*total price*/}
          <div className="cart-item__column cart-item__column--sm">
            <p className="txt--16 txt--pink">
              <strong>
                {cartItem.productId === giftId || cartItem.price === 0
                  ? "FREE"
                  : `$${(Math.round(cartItem.price * quantity * 100) / 100).toFixed(2)}`}
              </strong>
            </p>
          </div>
          {/*remove*/}
          <div className="cart-item__column cart-item__column--xs">
            <button
              className="cart-item__btn cart-item__remove"
              aria-label="Remove."
              onClick={() => removeLineItemInCart(cartItem)}
            >
              <i className="fa-solid fa-trash-can" />
            </button>
          </div>
        </div>
      </div>
      <div className="cart-item__columns--mobile d-block d-sm-none">
        <div className="d-flex align-items-center mb--10">
          {/*price*/}
          <div className="cart-item__column">
            <p className="txt--16">
              Price:{" "}
              <strong>
                {cartItem.productId === giftId || cartItem.price === 0
                  ? "FREE"
                  : `$${(Math.round(cartItem.price * 100) / 100).toFixed(2)}`}
              </strong>
            </p>
          </div>
          {/*quantity*/}
          <div className="cart-item__column">
            <Quantity
              cartItem={cartItem}
              giftId={giftId}
              quantity={quantity}
              handleQuantity={handleQuantity}
              modifierClass="cart-item__subscribed"
              noLabel={true}
            />
          </div>
          {/*total price*/}
          <div className="cart-item__column">
            <p className="txt--16 txt--pink">
              Total:{" "}
              <strong>
                {cartItem.productId === giftId || cartItem.price === 0
                  ? "FREE"
                  : `$${(Math.round(cartItem.price * quantity * 100) / 100).toFixed(2)}`}
              </strong>
            </p>
          </div>
        </div>

        <div className="cart-item__buttons">
          <button
            className="cart-item__btn cart-item__edit"
            aria-label="Review."
            onClick={() => handleReviewProduct(cartItem)}
          >
            Review Item
          </button>
        </div>
      </div>
    </div>
  )
}
