import React, { useEffect, useState } from "react"
import { Helmet } from "react-helmet"
import { Link } from "gatsby"

// Providers
import { useTheme } from "../context/ThemeContext"

// Hooks & Utils
import { UseShopifyDataQuery } from "../hooks/shopifyData"
import { isObjectEmpty } from "../utilities/checkValidity"
import lazyLoad from "../utilities/handleLazyLoad"

// Data
import FULL_PRODUCT_DATA from "../../static/data/full-product"
import FULL_APPAREL_DATA from "../../static/data/full-apparel"

// Components
import Loader from "../components/Loader/Loader"
import CartError from "../components/Cart/CartError"
import DiscountCodeBox from "../components/Discount/DiscountCodeBox"
import CartItem from "../components/Cart/CartItem"
import OrderSummary from "../components/Cart/OrderSummary"
const CartRecommended = lazyLoad(() => import("../components/Cart/CartRecommended"))
const ModalQuickBuy = lazyLoad(() => import("../components/Modals/ModalQuickBuy"))
const ModalSizeGuide = lazyLoad(() => import("../components/Modals/ModalSizeGuide"))
const ModalLegal = lazyLoad(() => import("../components/Modals/ModalLegal"))
const CartGTM = lazyLoad(() => import("../components/Cart/CartGTM"))
const Gift = lazyLoad(() => import("../components/Gift/Gift"))

const Cart = () => {
  const shopifyData = UseShopifyDataQuery()
  const { cart, pageLoader, isDiscountActive, subtotal, showGiftBox, giftId, shipping } = useTheme()

  /*============================
    QUICK BUY MODAL
   =============================*/
  const [isQuickBuyModalOpen, setIsQuickBuyModalOpen] = useState(false)

  const [shopifyItem, setShopifyItem] = useState("")
  const [clickedProduct, setClickedProduct] = useState(false)
  const [selectedCartItem, setSelectedCartItem] = useState({})

  const handleReviewProduct = cartItem => {
    setIsQuickBuyModalOpen(true)
    setClickedProduct(
      FULL_PRODUCT_DATA.hasOwnProperty(cartItem.productId)
        ? FULL_PRODUCT_DATA[cartItem.productId]
        : FULL_APPAREL_DATA[cartItem.productId],
    )
    setSelectedCartItem(cartItem)

    shopifyData.forEach((item, index) => {
      if (FULL_PRODUCT_DATA.hasOwnProperty(cartItem.productId)) {
        item.variants.forEach((variant, index) => {
          //TODO
          if (variant.shopifyId === cartItem.variantId) {
            setShopifyItem(item)
          }
        })
      } else {
        if (item.shopifyId === cartItem.productId) {
          setShopifyItem(item)
        }
      }
    })
  }

  // Set the shopify data for the selected product
  const handleShopify = item => setShopifyItem(item)

  // Set the {product/apparel}Data for the selected product
  const handleProduct = product => setClickedProduct(product)

  // Add/remove overflow hidden when modal is opened/closed
  useEffect(() => {
    if (isQuickBuyModalOpen) {
      document.body.classList.add("overflow--hidden")
    } else {
      document.body.classList.remove("overflow--hidden")
    }
  }, [isQuickBuyModalOpen])

  /*============================
     DISCOUNT MODAL
   =============================*/
  // open discount popup (for DiscountBox)
  // const handleDiscountPopup = () => {
  //   setIsQuickBuyModalOpen(true)
  //   setClickedProduct(FULL_APPAREL_DATA[""])
  //   shopifyData.forEach(item => {
  //     if (item.shopifyId === "gid://shopify/Product/6922418716864") {
  //       setShopifyItem(item)
  //     }
  //   })
  // }

  /*==================================
    INTERNATIONAL SHIPPING MODAL
  ==================================*/
  const [isLegalModalOpen, setIsLegalModalOpen] = useState(false)

  // Open Legal Modal
  const handleLegalModal = () => setIsLegalModalOpen(true)

  // Add/remove overflow hidden when modal is opened/closed
  useEffect(() => {
    if (isLegalModalOpen) {
      document.body.classList.add("overflow--hidden")
    } else {
      document.body.classList.remove("overflow--hidden")
    }
  }, [isLegalModalOpen])

  /*============================
   SIZE GUIDE MODAL
 =============================*/
  const [isModalSizeGuideOpen, setIsModalSizeGuideOpen] = useState(false)
  const [sizeGuideProduct, setSizeGuideProduct] = useState(null)

  const handleOpenModalSizeGuide = product => {
    setSizeGuideProduct(product)
    setIsModalSizeGuideOpen(true)
  }

  // Add/remove overflow hidden when modal is opened/closed
  useEffect(() => {
    if (isModalSizeGuideOpen) {
      document.body.classList.add("overflow--hidden")
    } else {
      document.body.classList.remove("overflow--hidden")
    }
  }, [isModalSizeGuideOpen])

  return (
    <>
      <Helmet>
        <title>Cart | The Betty Rocker</title>
      </Helmet>

      {/*loader*/}
      <Loader isLoader={pageLoader} />

      <CartGTM cart={cart} shopifyData={shopifyData} />

      <section className="bg--section section--padding-md cart--height">
        <div className="container">
          <CartError />

          <div className="cart mb--30">
            <div className="cart__items">
              {/*discount box*/}
              {isDiscountActive && <DiscountCodeBox modifierClass="discount-cart" isCart={true} />}

              <div className="cart__items__header">
                <h4 className="d-flex align-items-center txt--uppercase">
                  <strong>
                    your <span className="txt--pink">cart</span>
                  </strong>
                  {cart && cart.length > 0 && <span className="badge">{cart.length}</span>}
                </h4>
                {cart.length > 0 && (
                  <div className="cart-item__columns">
                    <span className="cart-item__column cart-item__column--sm">Price</span>
                    <span className="cart-item__column">Quantity</span>
                    <span className="cart-item__column cart-item__column--sm">Total Price</span>
                    <span className="cart-item__column cart-item__column--xs" />
                  </div>
                )}
              </div>

              {cart.length > 0 ? (
                <>
                  {cart &&
                    cart.map((cartItem, index) => (
                      <React.Fragment key={`cart-item-${index}`}>
                        {shopifyData.map((shopifyProduct, index) => (
                          <React.Fragment key={`cart-item-product-${index}`}>
                            {((shopifyProduct.variants.length &&
                              cartItem.variantId === shopifyProduct.variants[0].shopifyId) ||
                              cartItem.productId === shopifyProduct.shopifyId) && (
                              <CartItem
                                key={`cart-item-${isQuickBuyModalOpen}-${index}`}
                                shopifyProduct={shopifyProduct}
                                cartItem={cartItem}
                                handleReviewProduct={handleReviewProduct}
                              />
                            )}
                          </React.Fragment>
                        ))}
                      </React.Fragment>
                    ))}
                </>
              ) : (
                <div className="no-cart-items">
                  <p>Your shopping cart is currently empty.</p>
                </div>
              )}

              <div className="cart__items__footer">
                <Link to="/" className="cart-item__back">
                  <i className="fa-light fa-arrow-left-long" />
                  Continue Shopping
                </Link>
                <p className="txt--uppercase">
                  <strong>Subtotal: ${(Math.round(subtotal * 100) / 100).toFixed(2)}</strong>
                </p>
              </div>

              {showGiftBox && (
                <Gift shopifyData={shopifyData} handleOpenModalSizeGuide={handleOpenModalSizeGuide} isCart={true} />
              )}
            </div>

            <OrderSummary handleLegalModal={handleLegalModal} />
          </div>
        </div>

        {/*you might also like products*/}
        {cart.length > 0 && (
          <CartRecommended
            checkout={cart}
            shipping={shipping}
            shopifyData={shopifyData}
            handleOpenQuickBuyModal={() => {
              setIsQuickBuyModalOpen(true)
              setSelectedCartItem({})
            }}
            handleShopify={handleShopify}
            handleProduct={handleProduct}
          />
        )}
      </section>

      {isQuickBuyModalOpen && (
        <ModalQuickBuy
          handleCloseQuickBuyModal={() => {
            setIsQuickBuyModalOpen(false)
            setSelectedCartItem({})
          }}
          shopifyProduct={shopifyItem}
          product={clickedProduct}
          handleOpenModalSizeGuide={handleOpenModalSizeGuide}
          selectedProductColor={
            !isObjectEmpty(selectedCartItem) ? selectedCartItem.options.color : { name: "", color: "" }
          }
          selectedCartItem={!isObjectEmpty(selectedCartItem) ? selectedCartItem : {}}
          isCart={true}
        />
      )}

      {isModalSizeGuideOpen && (
        <ModalSizeGuide
          shopifyProduct={sizeGuideProduct}
          handleCloseModalSizeGuide={() => setIsModalSizeGuideOpen(false)}
        />
      )}

      {isLegalModalOpen && (
        <ModalLegal type={"international-shipping"} handleCloseModal={() => setIsLegalModalOpen(false)} />
      )}
    </>
  )
}
export default Cart
