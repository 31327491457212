import React from "react"
import { useTheme } from "../../context/ThemeContext"

const CartError = () => {
  const { error } = useTheme()

  return (
    <>
      {error.status.length > 0 && (
        <div className="cart-error">
          {error.status === "exceeds-stock" ? (
            <>
              <h6 className="font-lato">Important messages about items in your Cart:</h6>
              <p className="m--0">We're sorry.</p>
              <p
                dangerouslySetInnerHTML={{
                  __html: error.message,
                }}
              />
            </>
          ) : (
            <h6 className="font-lato">{error.message}</h6>
          )}
        </div>
      )}
    </>
  )
}
export default CartError
