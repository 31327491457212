import React, { useState } from "react"
import { useTheme } from "../../context/ThemeContext"
import lazyLoad from "../../utilities/handleLazyLoad"

// Components
const AccordionItem = lazyLoad(() => import("../Accordions/AccordionItem"))

/**
 * @param handleLegalModal - open legal modal
 * */
const OrderSummary = ({ handleLegalModal }) => {
  const { cart, checkoutUrl, subtotal, discount, total, country } = useTheme()
  // Handle review shipping policy
  const [reviewedShippingPolicy, setReviewedShippingPolicy] = useState(false)
  return (
    <div className="cart__sidebar">
      <h4 className="mb--20">
        <strong>Order Summary</strong>
      </h4>
      <div className="cart__sidebar__item">
        <strong>Subtotal</strong> ${(Math.round(subtotal * 100) / 100).toFixed(2)}
      </div>
      <div className="cart__sidebar__item">
        <span className="d-flex align-items-center">
          <strong>
            Shipping{" "}
            <span className="tooltip tooltip--sm ml--5" data-tooltip="Shipping is calculated at checkout.">
              <i className="fa-solid fa-circle-question" />
            </span>
          </strong>
        </span>{" "}
        Calculated at next step
      </div>
      <div className="cart__sidebar__item">
        <span className="d-flex align-items-center">
          <strong>
            Tax{" "}
            <span
              className="tooltip ml--5"
              data-tooltip="Taxes are based on your shipping location’s state and local sales tax."
            >
              <i className="fa-solid fa-circle-question" />
            </span>
          </strong>
        </span>{" "}
        Calculated at next step
      </div>
      {!!discount && (
        <div className="cart__sidebar__item">
          <span className="d-flex align-items-center txt--pink">
            <strong>Discount</strong>
          </span>{" "}
          <strong>-${discount}</strong>
        </div>
      )}
      <div className="cart__sidebar__item">
        <strong>TOTAL</strong>{" "}
        <span className="txt--pink">
          <strong>${(Math.round(total * 100) / 100).toFixed(2)}</strong>
        </span>
      </div>

      {country !== "US" && country !== "worldwide" ? (
        <>
          <AccordionItem
            title="<i class='fa-solid fa-circle-info txt--pink mr--5'></i> International Shipping Policy Info"
            modifierClasses="accordion-cart"
          >
            <p className="txt--14">
              <i>
                Canadian/United Kingdom customers, please refer to the
                <button
                  onClick={handleLegalModal}
                  className={"link txt--14 d-inline link--noline font-lato txt--underline mx--5"}
                >
                  International Shipping Policy
                </button>{" "}
                before placing an order for Whole Betty products or Betty Rocker Apparel/Merchandise. (Not applicable
                for meal plans and workout programs)
              </i>
            </p>
          </AccordionItem>
          {/*checkbox*/}

          <div className="checkbox--sm mb--20">
            <input
              type="checkbox"
              checked={reviewedShippingPolicy}
              onChange={event => setReviewedShippingPolicy(event.target.checked)}
              id="reviewedShippingPolicy"
            />
            <label htmlFor="reviewedShippingPolicy">
              I have reviewed the International Shipping Policy and agree to the terms.
            </label>
          </div>

          {cart.length > 0 && reviewedShippingPolicy ? (
            <a href={checkoutUrl} className="btn btn--full">
              go to checkout
            </a>
          ) : (
            <button disabled className="btn btn--full">
              go to checkout
            </button>
          )}
        </>
      ) : (
        <>
          {cart.length > 0 ? (
            <a href={checkoutUrl} className="btn btn--full">
              go to checkout
            </a>
          ) : (
            <button disabled className="btn btn--full">
              go to checkout
            </button>
          )}
        </>
      )}
    </div>
  )
}
export default OrderSummary
