// Utilities
import { extractSelectedOptionImageName } from "./formatString"
import { isObjectEmpty } from "./checkValidity"

/**
 * @param cartItem - the object of the product in cart
 * @param shopifyProduct - the shopify data for the product in cart
 * @returns string - the src of the image that should be shown for the product in cart
 * */
export const handleProductImage = (cartItem, shopifyProduct) => {
  if (isObjectEmpty(cartItem) || isObjectEmpty(shopifyProduct)) return

  let imageSrc = shopifyProduct.media.length ? shopifyProduct.media[0].image.originalSrc : ""

  let variantImgName = ""

  shopifyProduct.variants.forEach(variant => {
    if (variant.shopifyId === cartItem.variantId && variant.media.length) {
      // Get the first variant image src if there are multiple variants
      if (shopifyProduct.variants.length > 1) {
        variantImgName = variant.media[0].image.originalSrc
      }

      // Get the variant image src if the product is a promo offer
      if (shopifyProduct.tags.indexOf("promo") !== -1) {
        imageSrc = variant.media[0].image.originalSrc
      }
    }
  })

  // Get the corresponding gallery image for the selected variant
  if (variantImgName.length) {
    const optionType = extractSelectedOptionImageName(variantImgName)

    if (optionType.length) {
      shopifyProduct.media.forEach(media => {
        if (media.image.originalSrc.indexOf(`${optionType}-gallery`) !== -1) {
          imageSrc = media.image.originalSrc
        }
      })
    }
  }

  return imageSrc
}
